import { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import CommonHeader from "../Components/CommonHeader";
import Container from "../Components/uiElements/Container";

import classes from "./PostPage.module.css";

import parse from "html-react-parser";
import { useLoaderData } from "react-router-dom";

export const sendRequest = async ({ params }) => {
  const { postId } = params;
  console.log(postId);
  const response = await fetch(
    `https://silverjarz.com/wpdb/wp-json/wp/v2/posts/${postId}`
  );
  if (!response.ok) {
    throw new Error("Request failed!");
  }
  const data = await response.json();
  return data;
};

const PostPage = (params) => {
  console.log(params);
  const post = useLoaderData();

  const renderFirstSevenWords = (text) => {
    let pargraphMatch = /<p>(.*?)<\/p>/g;
    text = pargraphMatch.exec(text)[1].replace(/<[^>]*>/g, "");
    const words = text.split(" ");
    const firstSevenWords = words.slice(0, 7).join(" ");
    return firstSevenWords;
  };

  console.log(post);

  let postData = {};

  if (post) {
    postData = {
      title: post.title.rendered,
      description: post.content.rendered,
      imageSrc: post.uagb_featured_image_src.full[0],
    };
  }

  useEffect(() => {
    if (postData.title)
      document.title = `Silver Jarz | ${
        postData.title ? postData.title : "Page Not Found"
      }`;
    return () => {
      document.title = "Silver Jarz";
    };
  }, [postData.title]);

  const postLoaded = post;

  return (
    postLoaded && (
      <>
        <CommonHeader
          title={postData.title}
          description={renderFirstSevenWords(postData.description)}
          landingBg={postData.imageSrc}
          to="#details"
          className={classes["sub-page"]}
        />
        <section id="details">
          <Container className={classes.cont}>
            <main>
              <div className={classes.title}>
                <h2 className={classes["main-heading"]}>
                  Book Your{" "}
                  <span className={classes.bold}>{postData.title}</span> now!
                </h2>
                <div className={classes.review}>
                  <FontAwesomeIcon icon={faStar} className={classes.star} />
                  <FontAwesomeIcon icon={faStar} className={classes.star} />
                  <FontAwesomeIcon icon={faStar} className={classes.star} />
                  <FontAwesomeIcon icon={faStar} className={classes.star} />
                  <FontAwesomeIcon
                    icon={faStarHalfAlt}
                    className={classes.star}
                  />
                  <span>(1 review)</span>
                </div>
              </div>
              <section className={classes.content}>
                {parse(postData.description)}
              </section>
            </main>
          </Container>
        </section>
      </>
    )
  );
};

export default PostPage;
