import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import Slide from "./Slide";

import "./CustomSlider.css";
import classes from "./HeaderSlider.module.css";
import useHttp from "../Hooks/use-http";

const PrevArrow = ({ onClick }) => (
  <div className={classes.prev} onClick={onClick}>
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const NextArrow = ({ onClick }) => (
  <div className={classes.next} onClick={onClick}>
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const HeaderSlider = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    prevArrow: <></>,
    nextArrow: <></>,
    pauseOnHover: false,
  };

  const goToPreviousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const controlsRef = useRef(null);

  const [slideControlsStyle, setSlideControlsStyle] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });
  const [slides, setSlides] = useState([]);
  const { isLoading, error, sendRequest: fetchSlides } = useHttp();

  useEffect(() => {
    if (!isLoading && slides.length > 0) {
      const setPos = () => {
        if (window.innerWidth > 768) {
          const slider = sliderRef.current;
          const activeSlide =
            slider.innerSlider.list.querySelector(".slick-active");
          const activeSlideContent = activeSlide.querySelector(
            "[class*='slide-content']"
          );
          if (activeSlideContent) {
            let SlideContentPosition =
              activeSlideContent.getBoundingClientRect();
            setSlideControlsStyle({
              top: `${
                SlideContentPosition.top + SlideContentPosition.height / 1.25
              }px`,
              left: `${SlideContentPosition.width / 2 + 20}px`,
            });
          }
        } else {
          setSlideControlsStyle({});
        }
      };

      window.addEventListener("resize", setPos);
      setPos();

      return () => {
        window.removeEventListener("resize", setPos);
      };
    }
  }, [windowWidth, isLoading]);

  const transformSlides = (data) => {
    const slides = [];
    data.forEach((slide) => {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(
        slide.content.rendered,
        "text/html"
      );
      const title = slide.title.rendered;
      console.log(htmlDoc);
      const description = htmlDoc.querySelector("#description").innerHTML;
      const imageSrc = slide.uagb_featured_image_src.full[0];
      slides.push({ title, description, imageSrc });
    });
    setSlides(slides);
  };
  useEffect(() => {
    fetchSlides(
      { url: "https://silverjarz.com/wpdb/wp-json/wp/v2/posts?categories=10" },
      transformSlides
    );
  }, [fetchSlides]);
  return (
    <div className="landing">
      <Slider {...settings} ref={sliderRef}>
        {slides.map((slide, index) => {
          return (
            <Slide
              key={index}
              title={slide.title}
              desc={slide.description}
              img={slide.imageSrc}
            />
          );
        })}
      </Slider>
      <div
        className={classes.controls}
        ref={controlsRef}
        style={slideControlsStyle}
      >
        <PrevArrow onClick={goToPreviousSlide} />
        <NextArrow onClick={goToNextSlide} />
      </div>
    </div>
  );
};

export default HeaderSlider;
