import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import App from "./routes/App.jsx";
import RootLayout from "./routes/RootLayout.jsx";
import "./index.css";
import { SkeletonTheme } from "react-loading-skeleton";

import LoadingSpinner from "./Components/uiElements/LoadingSpinner.jsx";
import ErrorPage from "./routes/ErrorPage.jsx";
import PostPage, { sendRequest } from "./routes/SinglePostPage.jsx";


const Visas = lazy(() => import("./routes/Visas.jsx"));
const Tickets = lazy(() => import("./routes/Tickets.jsx"));
const Tours = lazy(() => import("./routes/Tours.jsx"));
const CarRental = lazy(() => import("./routes/CarRental.jsx"));
const Contact = lazy(() => import("./routes/Contact.jsx"));


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} >
      <Route index element={<App />} />
      <Route path="visas" element={<Visas />} />
      <Route path="tickets" element={<Tickets />} />
      <Route path="tours" element={<Tours />} />
      <Route path="car-rental" element={<CarRental />} />
      <Route path="contact" element={<Contact />} />
      <Route index path="car-rental/:postId" element={<PostPage />} errorElement={<ErrorPage />} loader={sendRequest} />
      <Route index path="tickets/:postId" element={<PostPage />} errorElement={<ErrorPage />} loader={sendRequest} />
      <Route index path="tours/:postId" element={<PostPage />} errorElement={<ErrorPage />} loader={sendRequest} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Suspense fallback={<LoadingSpinner />}>
    <SkeletonTheme highlightColor="#fff" baseColor="#f0f0f0" duration={1.5}>
      <RouterProvider router={router} />
    </SkeletonTheme>
  </Suspense>
);
