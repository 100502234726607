import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./ErrorPage.module.css";

import errorImage from "../assets/images/errorimage.png";
import Container from "../Components/uiElements/Container";

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Container className={styles.errorPage}>
        <h1>Something went wrong.</h1>
        <p>We apologize for the inconvenience.</p>
        <img src={errorImage} alt="Error" className={styles.errorImage} />
        <button onClick={handleGoBack}>Go Back</button>
      </Container>
    </>
  );
};

export default ErrorPage;
