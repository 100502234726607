import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../Components/uiElements/Container";
import classes from "./Footer.module.css";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={classes.footer}>
      <Container className={classes.footerContainer}>
        <div className={classes.top}>
          <div className={classes.menu}>
            <h3 className={classes.title}>Menu</h3>
            <Link to="/">Home</Link>
            <Link to="/visas">Visas</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className={classes.contact}>
            <h3 className={classes.title}>Contact Us</h3>
            <div className={classes.email}>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:info@silverjarz.com">info@silverjarz.com</a>
            </div>
            <div className={classes.phone}>
              <FontAwesomeIcon icon={faPhoneAlt} />
              <span>+96893579571</span>
            </div>
            <div className={classes.phone}>
              <FontAwesomeIcon icon={faPhoneAlt} />
              <span>+96891934140</span>
            </div>
            <div className={classes.address}>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>Khasab - Mudsandam - Oman</span>
            </div>
          </div>
          <div className={classes.location}>
            <h3 className={classes.title}>Find Us on Map</h3>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14321.58786762492!2d56.231417680366285!3d26.18376197933331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef6f5719ea09f07%3A0x3d6c9ca9eee51a97!2zU0lMVkVSIEpBUlogVFJBVkVMICYgVE9VUklTTSzYp9mE2KzYsdiyINin2YTZgdi22Yog2YTZhNiz2YHYsSDZiNin2YTYs9mK2KfYrdmH!5e0!3m2!1sen!2seg!4v1692953461363!5m2!1sen!2seg"
              style={{ border: 0 }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </Container>
      <div className={classes.bottom}>
        All Rights Reserved Silver Jarz travel and tourism
        <span className={classes.year}> {year}</span>
      </div>
    </footer>
  );
};

export default Footer;
