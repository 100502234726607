import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import classes from "./Navigation.module.css";
import logo from "../assets/images/sjlogo.png";
import hamStyles from "../assets/hamburgers.module.css";
import Container from "../Components/uiElements/Container";

const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const openNavHandler = () => {
    setIsNavOpen(!isNavOpen);
  };

  const HideNavHandler = () => {
    setIsNavOpen(false);
  };
  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavOpen]);
  return (
    <nav>
      <Container className={classes["nav-container"]}>
        <div className={classes.logo}>
          <Link to="/">
            <img src={logo} alt="SilverJarz" />
          </Link>
        </div>
        <button
          className={`${hamStyles.hamburger} ${
            hamStyles["hamburger--slider"]
          } ${isNavOpen ? hamStyles["is-active"] : ""}`}
          type="button"
          onClick={openNavHandler}
        >
          <span className={hamStyles["hamburger-box"]}>
            <span
              className={`${hamStyles["hamburger-inner"]} ${classes.innerHam}`}
            ></span>
          </span>
        </button>
        <ul
          className={`${classes["main-menu"]} ${classes.nav} ${
            isNavOpen ? classes.active : ""
          }`}
        >
          <li>
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? `${classes.active}` : ""
              }
              onClick={HideNavHandler}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="./visas"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? `${classes.active}` : ""
              }
              onClick={HideNavHandler}
            >
              Visas
            </NavLink>
          </li>
          <li>
            <NavLink
              to="./tickets"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? `${classes.active}` : ""
              }
              onClick={HideNavHandler}
            >
              Tickets
            </NavLink>
          </li>
          <li>
            <NavLink
              to="./tours"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? `${classes.active}` : ""
              }
              onClick={HideNavHandler}
            >
              Tours
            </NavLink>
          </li>
          <li>
            <NavLink
              to="./car-rental"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? `${classes.active}` : ""
              }
              onClick={HideNavHandler}
            >
              Car Rental
            </NavLink>
          </li>
          <li>
            <NavLink
              to="./contact"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? `${classes.active}` : ""
              }
              onClick={HideNavHandler}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </Container>
    </nav>
  );
};

export default Navigation;
