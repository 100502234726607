import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Offer from "./Offer";
import "./OffersSlider.css";

import offers from "../DummyData/tickets.json";

const Offers = () => {
  const [slidesNumber, setSlidesNumber] = useState(3);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });
  useEffect(() => {
    windowWidth > 768 ? setSlidesNumber(3) : setSlidesNumber(1);
  }, [windowWidth]);
  const settings = {
    dots: false,
    infinite: true,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: slidesNumber,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  return (
    <div className="promoSlider">
      <Slider {...settings}>
        {offers.map((offer) => (
          <Offer
            img={offer.imageSrc}
            to={offer.to}
            title={offer.title}
            code="1234"
            key={offer.id}
            desc={offer.description}
            cta="احجز الآن"
            id={offer.id}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Offers;
